import React from "react"
import Layout from '../layouts/layout';
import Head from '../components/head';
import classes from './home.module.scss';
export default () => (
  <Layout>
    <Head title="Home"/>
    <h1>&lt;Hello /&gt;</h1>
    <h2 className={classes.subheading}>I am a <span className={classes.shadow}>developer</span>, <span className={classes.shadow}>designer</span> and <span className={classes.shadow}>learner</span>, living in Beautiful <span className={classes.shadow}>India</span>.</h2>
    {/* <ul className={classes.ul}>
      <li><img src="/img/lang/react.svg" alt="react"/></li>
      <li><img src="/img/lang/gulp.svg" alt="react"/></li>
      <li><img src="/img/lang/nodejs.svg" alt="react"/></li>
      <li><img src="/img/lang/php.svg" alt="react"/></li>
      <li><img src="/img/lang/angular.svg" alt="react"/></li>
      <li><img src="/img/lang/ionic.svg" alt="react"/></li>
      <li><img src="/img/lang/laravel.svg" alt="react"/></li>
    </ul> */}
  </Layout>
)
